import React, { useEffect, useState } from "react";

interface iProps {
  showComponent: boolean;
  length?: string;
};

export const Fade:React.FC<iProps> = ({ children, showComponent, length }) => {
  const [display, setDisplay] = useState(showComponent);
  const timeLength = length || `1s`;

  useEffect(() => {
    if (showComponent) setDisplay(true);
  }, [showComponent]);

  const onAnimationEnd = () => {
    if (!showComponent) setDisplay(false);
  };

  return (
    display 
      ? (
        <div
          style={{ animation: `${showComponent ? "fadeIn" : "fadeOut"} ${timeLength}` }}
          onAnimationEnd={onAnimationEnd}
        >
          {children}
        </div>
      )
      : <></>
  );
};
