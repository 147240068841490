import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { CAABannerH2, CAABannerH3 } from "../helpers/styledComponents";
import { IDynamicContent } from "../helpers/types";
import CAABanner from "./CAABanner";


export default ({ content } : { content: IDynamicContent }) =>{
  //decide if content should be visible
  const now = new Date();
  const startDate = new Date(content.startDate);

  if(startDate.getTime() >= now.getTime()){
    return null;
  }

  if(content.endDate){
    const endDate = new Date(content.endDate);
    if(endDate.getTime() < now.getTime()){
      return null; 
    }
  }
  
  return(
    <CAABanner backgroundcolor={content.backgroundColor}>
      {content.displayTitle &&(
        <CAABannerH2>
          {content.title}
        </CAABannerH2>
      )}
      <CAABannerH3>
        {documentToReactComponents(JSON.parse(content.content.raw))}
      </CAABannerH3>
    </CAABanner>
  );
}

