import React from "react";
import { ResponsiveContainer } from "../helpers/styledComponents";
import styled from "styled-components";

const CAABannerContainer = styled.header<{backgroundcolor?: string}>`
  width: 100%;
  color: ${props =>{
    switch (props.backgroundcolor) {
      case "Black":
        return "#fff";
      case "White":
        return "#000";
      case "None":
        return "#000";
      case "Red":
      default:
        return "#fff";
    }
  }};
  background-color: ${props => {
    switch (props.backgroundcolor) {
      case "Black":
        return "#000";
      case "White":
        return "#fff";
      case "None":
        return "none";
      case "Red":
      default:
        return "#bc1f2e";
    }
  }};
  a {
    color: ${props =>{
      switch (props.backgroundcolor) {
        case "Black":
          return "#fff";
        case "White":
          return "#000";
        case "None":
          return "#000";
        case "Red":
        default:
          return "#fff";
      }
    }};
    text-decoration: underline;
  }
  min-height: 60px;
  
  padding: 1.5rem;
  line-height: 1.25;
  
  h3 {
    font-size: 1.25rem;
  }
`

interface iProps {
  backgroundcolor?: string; 
  style?: object;
}

const CAABanner:React.FC<iProps> = ({children, backgroundcolor, style}) => {
  return (
    <CAABannerContainer style={style} backgroundcolor={backgroundcolor}>
      <ResponsiveContainer>
        {children}
      </ResponsiveContainer>
    </CAABannerContainer>
  );
}

export default CAABanner;