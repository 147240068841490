import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Grid, Icon } from "semantic-ui-react";
import { ResponsiveContainer, FooterLogo } from "../helpers/styledComponents";
import whiteLogo from '../../static/mediaLogos/civic-alliance-logo.png';

const StyledLink = styled(Link)`
  color: white;
  text-decoration: underline;
  display: inline-block;
  
  &:hover {
    color: #e6e6e6;
    text-decoration: underline;
  }
`;

const StyledA = styled.a`
  color: white;
  text-decoration: underline;

  &:hover {
    color: #e6e6e6;
    text-decoration: underline;
  }
`;

const SocialMediaA = styled.a`
  color: white;
  text-decoration: none;

  &:hover {
    color: #e6e6e6;
  }

`;

const StyledFooter = styled.footer`
  width: 100%;
  background-color: #0a0a0a;
  color: white;
  padding: 3rem 1.5rem;

  @media (min-width: 769px) {
    padding: 9rem 1.5rem;
  }

  @media (min-width: 960px) {
    padding-bottom: 4rem;
  }
`
const GridRow = styled(Grid.Row)`
  margin-bottom: 1em;
  font-size: 1em;
  text-align: right;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 14px;
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <ResponsiveContainer style={{ padding: "0 5em" }}>
        <Grid stackable columns={2} style={{ margin: "-.75em -1.5rem"}}>
          <Grid.Column textAlign="left" style={{ padding: ".75rem 1.5rem"}}>
            <FooterLogo src={whiteLogo} />
          </Grid.Column>
          <Grid.Column textAlign="right" style={{ padding: ".75rem 1.5rem"}}>
            <GridRow>
              <SocialMediaA href="https://twitter.com/civicalliance" target="_blank" rel="noopener noreferrer">
                <Icon size="big" fitted name="twitter square" />
              </SocialMediaA>
              <SocialMediaA style={{ marginLeft: "10px" }} href="https://linkedin.com/company/civicalliance" target="_blank" rel="noopener noreferrer">
                <Icon size="big" fitted name="linkedin square" />
              </SocialMediaA>
            </GridRow>
            <GridRow>
              <StyledLink to="/press-media">Press &amp; Media</StyledLink>
            </GridRow>
            <GridRow>
              <StyledLink to="/privacy" style={{marginRight: "15px"}}>Privacy</StyledLink>
              <StyledLink to="/terms">Terms of Use</StyledLink>
            </GridRow>
            <GridRow style={{ marginBottom: 0 }}>
              To learn more or join, email&nbsp;
              <StyledA href="mailto:join@civicalliance.com" style={{ marginBottom: 0 }}>join@civicalliance.com</StyledA>
            </GridRow>
          </Grid.Column>
        </Grid>
      </ResponsiveContainer>
    </StyledFooter>
  )
}

export default Footer
