import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { ResponsiveContainer, HeaderLogo } from "../helpers/styledComponents";
import styled from "styled-components";
type Mode = "light" | "dark";

const StyledHeader = styled.header<{ mode: Mode }>`
  padding: 3rem 1.5rem;
  background-color: ${props => (props.mode === "light" ? "#fff" : "#0a0a0a")};
  color: ${props => (props.mode === "light" ? "#000" : "#fff")};
`;

interface IProps {
  data: any;
  children: any;
  mode: Mode;
}

const Header: React.FC<IProps> = ({ data, children, mode }) => {
  let whiteLogo = data.allFile.edges.find(({node}) => node.name === "civic-alliance-logo");
  let blackLogo = data.allFile.edges.find(({node}) => node.name === "civic-alliance-logo-black");
  
  return (
    <StyledHeader mode={mode}>
      <ResponsiveContainer>
        <Link to='/'>
          <HeaderLogo src={mode === "dark" ? whiteLogo.node.publicURL : blackLogo.node.publicURL} />
        </Link>
        {children}
      </ResponsiveContainer>
    </StyledHeader>
  );
};

export default function MyHeader(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: {sourceInstanceName: {eq: "mediaLogos"}}) {
            edges {
              node {
                id
                name
                publicURL
              }
            }
          }
        }
      `}
      render={data => <Header data={data} {...props} />}
    />
  )
}

