import React, { useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import Header from "./Header";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";
import { Fade } from "./Fade";
import { JoinCivicAllianceButton } from  "./Buttons";
import { Helmet } from "react-helmet";
import { Container } from "semantic-ui-react";
import TopHeaderBannerAllPages from "./TopHeaderBannerAllPages";
type Mode = "light" | "dark";

const GlobalStyles = createGlobalStyle<{ mode: Mode }>`
  body {
    padding-top: 60px; //fixed view with navigation bar
  }
  &&&& {
    html, body, #___gatsby, #gatsby-focus-wrapper {
      height: 100%;
      font-family: Shape,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
      font-size: 1em;
      font-weight: 400;
      line-height: 1.5;
  
      h1, h2, h3, h4, h5, a, button {
        font-family: Shape,"Helvetica Neue",Helvetica,Arial,sans-serif !important;
      }
  
      @media (max-width: 769px) {
        font-size: 14px;
      }
    }
  }
`;

const ViewPort = styled(Container)`
  &&& {

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;

const FixedJoinBanner = styled.div`
  background: #000;
  text-align: center;
  padding: 15px;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10px;
  border-top: 1px solid #888;
  display: block;
`;
interface IProps {
  mode: Mode
  caaBannerContent?: JSX.Element;
  headerContent?: JSX.Element;
  startCounter?: () => void;
  setScrollData?: ({scrollHeight, scrollTop}: {scrollHeight: number, scrollTop: number} ) => void;
  title?: string;
  description?: string;
};
declare global {
  interface Window {
    OneTrust: any
  }
}

const Layout: React.FC<IProps> = ({
  children,
  mode,
  caaBannerContent,
  headerContent,
  startCounter,
  setScrollData,
  title = "Civic Alliance",
  description = "The Civic Alliance is helping businesses work toward a complete and accurate count in 2020 because we know how important Census data is for our companies, our communities, and our country."
}) => {
  const FixedBanner = useRef(null);
  const [showBanner, setShowBanner] = useState(false);

  const handleScroll = (e) => {
    const element = e.target;
    const scrollTop = element.scrollTop;
    const scrollHeight = element.scrollHeight;
    const newShowValue = scrollTop > 400 && (scrollTop + 1500) < scrollHeight;
    setShowBanner(newShowValue);
    startCounter && startCounter();
    setScrollData && setScrollData({ scrollHeight, scrollTop });
  };

  return (
    <ViewPort fluid onScroll={(e) => handleScroll(e)}>
      <GlobalStyles mode={mode}/>
      <Helmet>
        <title>{title}</title>
        <meta name="author" content="Civic Alliance"/>
        <meta charSet="utf-8" />
        <html lang="en" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 user-scalable=no"
        />

        <meta property="og:description" content={description} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:image" content="https://www.civicalliance.com/mediaLogos/civic-alliance-share.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta property="og:site_name" content={title} />

        <meta property="twitter:site" content="civicalliance" />
        <meta property="twitter:creator" content="civicalliance" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />  
        <meta property="twitter:image:src" content="https://www.civicalliance.com/mediaLogos/civic-alliance-share.png" />
        <meta property="twitter:image:alt" content="Civic Alliance Logo" />
        

        <link rel="apple-touch-icon" sizes="57x57" href="/app-icons/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/app-icons/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/app-icons/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/app-icons/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/app-icons/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/app-icons/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/app-icons/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/app-icons/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/app-icons/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192"  href="/app-icons/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/app-icons/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/app-icons/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/app-icons/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#000000" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/app-icons/ms-icon-144x144.png" />


        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charSet="UTF-8"
          data-domain-script="3cdbf87c-b103-4dc3-827e-df1a67325576"
        />
        <script 
          src="https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js" 
          type="text/javascript" 
          charSet="UTF-8" 
          id="otprivacy-notice-script"
        >
          settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"
        </script>
      </Helmet>
      
      {/* CAA Banner specify in individual page */}
      {caaBannerContent && (
        <>{caaBannerContent}</>
      )}

      {/* CAA Banner that will be render in all pages */}
      <TopHeaderBannerAllPages />

      <NavigationBar />
      <Header mode={mode}>{headerContent}</Header>
      {children}
      <Footer />
      <Fade showComponent={showBanner} length=".5s">
        <FixedJoinBanner ref={FixedBanner}>
          <JoinCivicAllianceButton mode="light"/>
        </FixedJoinBanner>
      </Fade>
    </ViewPort>
  )
};

export default Layout;