import React from "react";
import { Link } from "gatsby";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

type Mode = "light" | "dark";

const CenterDiv = styled.div`
  width: 100%; 
  text-align: center;
`;

interface iProps {
  mode: Mode;
  short?: boolean;
  center?: boolean;
  style?: object;
  large?: boolean;
  larger?: boolean;
}

interface ButtonAnchorProps {
  href: string;
  mode: Mode;
  center?: boolean;
  style?: object;
  large?: boolean;
  larger?: boolean;
};

interface ButtonLinkProps {
  mode: Mode;
  to: string;
  center?: boolean;
  style?: object;
  large?: boolean;
  larger?: boolean;
  newTab?: boolean;
};

export const JoinCivicAllianceButton: React.FC<iProps> = ({ mode, short, center, style, large, larger }) => {
  const defaultStyle = {
    padding: ".5em 1em",
    height: "2.5em",
    alignItems: "center",
    display: "inline-flex"
  };

  const styleProp = Object.assign({}, defaultStyle, style);
  
  const AnchorButton = (
    <ButtonAnchor href="https://cvcallnce.com/join" mode={mode} style={styleProp} large={large} larger={larger}>
      {`Join ${short ? "Us" : "the Civic Alliance"}`}
    </ButtonAnchor>
  );

  return center ? <CenterDiv>{ AnchorButton }</CenterDiv> : AnchorButton;
};

export const ButtonAnchor: React.FC<ButtonAnchorProps> = ({ children, mode, href, center, style, large, larger }) => {
  const color = mode === "light" ? "#0a0a0a" : "#f9f9f9";
  const backgroundColor = mode === "light" ? "#f9f9f9" : "#0a0a0a";
  const styleProp = Object.assign({}, { color: color, backgroundColor: backgroundColor, fontSize: "20px", fontWeight: 700 }, style);

  if (large) { styleProp.fontSize = "1.25em"; }
  if (larger) { styleProp.fontSize = "1.5em"; }

  const AnchorButton = (
    <Button 
      as="a" 
      style={styleProp}
      href={href} 
      target="_blank" rel="noopener noreferrer"
    >
      {children}      
    </Button>
  );

  return center ? <CenterDiv>{AnchorButton}</CenterDiv> : AnchorButton;
};

export const ButtonLink: React.FC<ButtonLinkProps> = ({ children, mode, to, center, style, large, larger, newTab }) => {
  const color = mode === "light" ? "#0a0a0a" : "#f9f9f9";
  const backgroundColor = mode === "light" ? "#f9f9f9" : "#0a0a0a";
  const styleProp = Object.assign({}, { color: color, backgroundColor: backgroundColor, fontSize: "20px", fontWeight: 700 }, style)

  if (large) { styleProp.fontSize = "1.25em"; }
  if (larger) { styleProp.fontSize = "1.5em"; }

  const LinkButton = (
    <Link to={to} target={newTab ? "_blank" : undefined}>
      <Button style={styleProp}>
        {children}
      </Button>
    </Link>
  );

  return center ? <CenterDiv>{ LinkButton }</CenterDiv> : LinkButton;
};